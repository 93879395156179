<template>
  <div class="threejz-page">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'SingleDay',
};
</script>
<style scoped lang="scss">
  .threejz-page {
    width: 100%;
    max-width: 750px;
    margin: auto;
    min-height: 100%;
    position: relative;
    // background-color: #F6F6F6;
    font-family: PingFangSC-Regular, PingFang SC;
  };
</style>
